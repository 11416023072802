import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import Pwa from "./Pwa";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootRouter from "./RootRouter";
import { setSettings } from "./functions/settingsFunc";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootRouter />);

setSettings();
