/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Pwa from "./Pwa";
import { getLanguage } from "./functions/languages";

function RootRouter() {
	const [data, setData] = useState(null);

	useEffect(() => {
		const lang = getLanguage();
		const title = document.getElementById("titleApp");
		const icon = document.getElementById("iconApp");
		const iconApple = document.getElementById("iconAppApple");

		if (data !== null) return;
		// Завантаження даних при завантаженні компонента
		fetch(process.env.PUBLIC_URL + "/data.json")
			.then(response => {
				if (!response.ok) {
					throw new Error("Failed to fetch the file");
				}
				return response.json();
			})
			.then(jsonData => {
				const currentObjLang = Object.keys(jsonData.langs).find(item => item.includes(lang));
				title.textContent = jsonData.langs[currentObjLang || "en"].appName;
				// icon.setAttribute(
				// 	"href",
				// 	`${jsonData?.domain}/${jsonData?.id}/images/icons/${currentObjLang}/${
				// 		jsonData?.langs[currentObjLang || "en"].appIcon
				// 	}`,
				// );
				// iconApple.setAttribute(
				// 	"href",
				// 	`${jsonData?.domain}/${jsonData?.id}/images/icons/${currentObjLang}/${
				// 		jsonData?.langs[currentObjLang || "en"].appIcon
				// 	}`,
				// );

				if (!currentObjLang) {
					setData({
						...jsonData,
						langs: { ...jsonData.langs["en"], lang: ["en"] },
					});
				} else {
					setData({
						...jsonData,
						langs: { ...jsonData.langs[currentObjLang], lang: currentObjLang },
					});
				}
			})
			.catch(error => console.error("Error loading JSON:", error));
	}, []);

	const router = createBrowserRouter([
		{
			path: "/pwa",
			element: <Pwa data={data} />,
		},
		{
			path: "/",
			element: <App data={data} />,
		},
	]);

	return <RouterProvider router={router} />;
}

export default RootRouter;
