/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const FacebookPixel = ({ data }) => {
	const location = useLocation();
	const pixel = useRef(null);
	const [runFbScript, setRunFbScript] = useState(false);

	const getCookieValue = name => {
		const cookies = document.cookie.split("; ");
		for (let cookie of cookies) {
			const [key, value] = cookie.split("=");
			if (key === name) {
				return decodeURIComponent(value);
			}
		}
		return null;
	};

	const setCookie = (name, value, days) => {
		let expires = "";
		if (days) {
			const date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
	};

	const getUserData = async () => {
		try {
			const response = await fetch("https://test.traffhunt.com/api/getUserIP");
			if (!response.ok) throw new Error("Network response was not ok");
			const resId = await response.json();
			return resId.data;
		} catch (error) {
			console.error("Failed to get IP:", error);
			return null;
		}
	};

	const generateFbp = () => {
		const timestamp = Math.floor(Date.now() / 1000);
		const random = Math.floor(Math.random() * 9000000000) + 1000000000;
		return `fb.1.${timestamp}.${random}`;
	};

	const generateFbc = fbclid => {
		if (!fbclid) return null;
		const timestamp = Math.floor(Date.now() / 1000);
		return `fb.1.${timestamp}.${fbclid}`;
	};

	useEffect(() => {
		if (!data) return;
		if (localStorage.getItem("lead_id") || getCookieValue("lead_id")) return;

		(async () => {
			const lead_pixel_id_local = localStorage.getItem("lead_pixel_id");
			const lead_pixel_id_cookie = getCookieValue("lead_pixel_id");

			const searchParams = new URLSearchParams(location.search);
			const { ip, user_agent } = await getUserData();
			const id = data?.id;
			const pixel_fb_id = searchParams.get("pixel");
			const link_id = searchParams.get("lnk_id");
			const fbclid = searchParams.get("fbclid");
			const campaign_name = searchParams.get("campaign_name");
			const campaign_id = searchParams.get("campaign_id");
			const ad_id = searchParams.get("ad_id");
			const ad_name = searchParams.get("ad_name");
			const adset_id = searchParams.get("adset_id");
			const adset_name = searchParams.get("adset_name");
			const placement = searchParams.get("placement");

			if (
				!pixel_fb_id &&
				!link_id &&
				!fbclid &&
				!campaign_name &&
				!campaign_id &&
				!ad_id &&
				!ad_name &&
				!adset_id &&
				!adset_name &&
				!placement
			)
				return;

			if (!lead_pixel_id_cookie && !lead_pixel_id_local) {
				pixel.current = pixel_fb_id;

				if (!isNaN(pixel_fb_id) && isFinite(pixel_fb_id) && !!pixel_fb_id) {
					const fbp = generateFbp();
					const fbc = generateFbc(fbclid);

					const res = await fetch(`https://test.traffhunt.com/api/LeadPixels/App/addLeadPixel`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							ip,
							user_agent,
							app_id: Number(id),
							pixel_fb_id: Number(pixel_fb_id),
							link_id: Number(link_id),
							fbclid,
							campaign_name,
							campaign_id,
							ad_id,
							ad_name,
							adset_id,
							adset_name,
							placement,
							fbp,
							fbc,
						}),
					});

					const result = await res.json();

					if (!!result.success) {
						setCookie("lead_pixel_id", result?.data?.id, 30);
						setCookie("pixel", pixel_fb_id, 30);
						setCookie("link_id", result?.data?.link_id, 30);
						localStorage.setItem("lead_pixel_id", result?.data?.id);
						localStorage.setItem("pixel", pixel_fb_id);
						localStorage.setItem("link_id", result?.data?.link_id);
						setRunFbScript(true);
					} else {
						setCookie("link_id", link_id, 30);
						localStorage.setItem("link_id", link_id);
					}
				} else {
					if (!Number(getCookieValue("link_id")) || !Number(localStorage.getItem("link_id"))) {
						setCookie("link_id", link_id, 30);
						localStorage.setItem("link_id", link_id);
					}
				}
			} else {
				const lead_id = getCookieValue("lead_id") || localStorage.getItem("lead_id");

				if (!!lead_id) return;

				(async () => {
					const res = await fetch(
						`https://test.traffhunt.com/api/LeadPixels/App/getLeadPixel?id=${
							lead_pixel_id_cookie || lead_pixel_id_local
						}`,
					);

					const result = await res.json();
					setCookie("link_id", result?.data?.link_id, 30);
					localStorage.setItem("link_id", result?.data?.link_id);
				})();
			}
		})();
	}, [data]);

	useEffect(() => {
		if (!runFbScript) return;

		!(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = true;
			n.version = "2.0";
			n.queue = [];
			t = b.createElement(e);
			t.async = true;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

		fbq("init", pixel.current);
		fbq("track", "PageView");

		const getFbpc = () => {
			const fbc = document.cookie.match("(^|;)\\s*_fbc\\s*=\\s*([^;]+)");
			const fbp = document.cookie.match("(^|;)\\s*_fbp\\s*=\\s*([^;]+)");

			if (!fbc && !fbp) {
				const timeout = setTimeout(() => {
					getFbpc();
				}, 1000);
			} else {
				const lead_pixel_id =
					getCookieValue("lead_pixel_id") || localStorage.getItem("lead_pixel_id");
				const fbp = getCookieValue("_fbp");
				const fbc = getCookieValue("_fbc");

				(async () => {
					await fetch(`https://test.traffhunt.com/api/LeadPixels/App/updateLeadPixel`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							id: lead_pixel_id,
							fbp,
							fbc,
						}),
					});
				})();
			}
		};

		getFbpc();
	}, [runFbScript]);

	return (
		<>
			{runFbScript && (
				<noscript>
					<img
						height="1"
						width="1"
						style={{ display: "none" }}
						src={`https://www.facebook.com/tr?id=${pixel.current}&ev=PageView&noscript=1`}
						alt="facebook-pixel"
					/>
				</noscript>
			)}
		</>
	);
};

export default FacebookPixel;
