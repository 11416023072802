/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect, useRef } from "react";
import { getDeviceAndBrowser } from "./functions/device";
import numeral from "numeral";
import moment from "moment";
import FacebookPixel from "./FacebookPixel";

const App = ({ data }) => {
	const [status, setStatus] = useState("download");
	const [progress, setProgress] = useState(0);
	const [progressWidth, setProgressWidth] = useState(0);
	const [device, setDevice] = useState({});

	const [isLoader, setIsLoader] = useState(true);

	const parseCookies = cookieString => {
		const cookiePairs = cookieString.split(";");
		const cookiesObject = {};

		cookiePairs.forEach(pair => {
			const [key, value] = pair.trim().split("=");

			cookiesObject[key] = value;
		});

		return cookiesObject;
	};

	window.isPWA = function () {
		return (
			window.matchMedia("(display-mode: standalone)").matches ||
			window.navigator.standalone ||
			document.referrer.includes("android-app://")
		);
	};

	useEffect(() => {
		if (status !== "downloading") return;
		let progressWidth = 0;
		const interval = setInterval(() => {
			progressWidth += Math.min(100 - progressWidth, Math.floor(Math.random() * 5 + 1));
			setProgressWidth(progressWidth);

			if (progressWidth >= 100) {
				setStatus("install");
				clearInterval(interval);
			}
		}, Math.floor(Math.random() * 201 + 250));
	}, [progress, status]);

	useEffect(() => {
		const body = document.querySelector("body");
		body.style.overflow = "hidden";
		const images = document.querySelectorAll("img");
		let loadedCount = 0;

		// Функція для перевірки чи всі зображення завантажились
		const checkAllImagesLoaded = () => {
			loadedCount++;
			if (loadedCount === images.length) {
				setTimeout(() => {
					body.style.overflow = "visible";
					setIsLoader(false);
					console.log("Всі зображення завантажились");
				}, 1000);
			}
		};

		// Додаємо обробник події для кожного зображення
		images.forEach(img => {
			if (img.complete) {
				checkAllImagesLoaded();
			} else {
				img.addEventListener("load", checkAllImagesLoaded);
				img.addEventListener("error", checkAllImagesLoaded); // для обробки помилок завантаження
			}
		});

		// Прибираємо обробники після завершення
		return () => {
			images.forEach(img => {
				img.removeEventListener("load", checkAllImagesLoaded);
				img.removeEventListener("error", checkAllImagesLoaded);
			});
		};
	}, []);

	useEffect(() => {
		if (!data) return;
		data.langs.rtl && document.documentElement.setAttribute("dir", "rtl");
		setDevice(getDeviceAndBrowser());
		window.addEventListener("beforeinstallprompt", async function (e) {
			e.preventDefault();
			setStatus("install");
			window.deferredPrompt = e;
			console.log(e);
		});
		let intervalId = setInterval(async () => {
			let appInstalled = await navigator.getInstalledRelatedApps();

			if (appInstalled.length > 0) {
				setStatus("open");
				clearInterval(intervalId);
			}
		}, 500);
	}, [data]);

	const handleDownload = () => {
		if (device.deviceType === "Desktop") {
			return setStatus("install");
		}

		if (device.deviceType === "Android") {
			setStatus("downloading");
		}
	};

	const getUserData = async () => {
		try {
			const response = await fetch("https://test.traffhunt.com/api/getUserIP");
			if (!response.ok) throw new Error("Network response was not ok");
			const resId = await response.json();
			return resId.data;
		} catch (error) {
			console.error("Failed to get IP:", error);
			return null;
		}
	};

	const handleInstall = async () => {
		setStatus("installing");
		const { ip, user_agent } = await getUserData();
		if (window.deferredPrompt != null) {
			window.deferredPrompt.prompt();

			const { outcome } = await window.deferredPrompt.userChoice;
			if (outcome === "accepted") {
				const obgCookies = parseCookies(document.cookie);

				const obj = {
					app_id: data.id,
					ip,
					user_agent,
					link_id:
						Number(obgCookies?.link_id) ||
						Number(localStorage.getItem("link_id")) ||
						Number(data?.link_id),
				};

				if (
					!!Number(obgCookies?.lead_pixel_id) ||
					!!Number(localStorage.getItem("lead_pixel_id"))
				) {
					obj.lead_pixel_id =
						Number(obgCookies?.lead_pixel_id) || Number(localStorage.getItem("lead_pixel_id"));
				}

				const res = await fetch(`https://test.traffhunt.com/api/Leads/App/addLead`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(obj),
				});

				const response = await res.json();
				localStorage.setItem("lead_id", response.data.id);
			}
		}
	};

	const handleOpen = () => {
		if (window.isPWA()) {
			window.open("/pwa", "_self");
		} else {
			window.open(`/pwa`, "_blank");
		}
	};

	//popup

	const [currentImage, setCurrentImage] = useState(0); // індекс поточного зображення
	const [isPopupOpen, setIsPopupOpen] = useState(false); // стан відкритого/закритого попапу

	const openPopup = index => {
		setCurrentImage(index); // встановлюємо поточне зображення
		setIsPopupOpen(true); // відкриваємо попап
		document.body.style.overflow = "hidden"; // блокуємо прокрутку сторінки
	};

	const closePopup = () => {
		setIsPopupOpen(false); // закриваємо попап
		document.body.style.overflow = "auto"; // повертаємо прокрутку сторінки
	};

	const handlePrevImage = () => {
		if (currentImage > 0) {
			setCurrentImage(currentImage - 1); // переходимо до попереднього зображення
		}
	};

	const handleNextImage = () => {
		if (currentImage < data.langs.slides.length - 1) {
			setCurrentImage(currentImage + 1); // переходимо до наступного зображення
		}
	};

	return (
		<>
			<FacebookPixel data={data} />
			<div className="layout">
				<div className="container">
					<div className="header">
						<div className="header-top">
							{status !== "verifying" && status !== "downloading" && status !== "installing" && (
								<img
									className="head-img"
									id="app_logo_large"
									src={`${data?.domain_images}/${data?.id}/images/icons/${data?.langs?.lang}/${data?.langs?.appIcon}`}
								/>
							)}
							{(status === "verifying" || status === "installing") && (
								<div
									className="img-loading"
									id="loading_app_logo"
								>
									<img
										className="head-img"
										src={`${data?.domain_images}/${data?.id}/images/icons/${data?.langs?.lang}/${data?.langs?.appIcon}`}
										id="app_logo_loading"
									/>
								</div>
							)}
							{status === "downloading" && (
								<div
									className="img-download"
									id="download_app_logo"
								>
									<img
										className="head-img"
										src={`${data?.domain_images}/${data?.id}/images/icons/${data?.langs?.lang}/${data?.langs?.appIcon}`}
										id="app_logo_svg"
									/>
									<svg
										width="72"
										height="72"
										className="img-loading-circle-svg"
										id="progressDownload"
									>
										<circle
											id="progress"
											cx="36"
											cy="36"
											r="35"
											stroke="#0B57CF"
											strokeWidth="2"
											strokeDashoffset={`${220 - (progressWidth * 220) / 100}`}
											fill="none"
										/>
									</svg>
								</div>
							)}
							<div>
								<h1 id="appName">{data?.langs?.appName}</h1>
								{(status === "download" ||
									status === "open" ||
									status === "installing" ||
									status === "install") && (
									<div
										id="noinstall_subtitle"
										className="header-links"
									>
										<span>Google</span>
										<br />
										<p id="headerContains">
											{data?.langs?.inscriptions?.contains_1} &middot;{" "}
											{data?.langs?.inscriptions?.contains_2}
										</p>
									</div>
								)}
								{status === "downloading" && (
									<div
										id="installing_subtitle"
										className="header-loding"
									>
										{/* <h3>Installing...</h3>  */}
										<div>
											<div>
												<div id="progressWidth"></div>
											</div>
											<div id="progressWidth-info">
												{progressWidth}% from {data?.langs?.appSize} MB
											</div>
										</div>

										<p>
											<img src="/images/other/play.jpeg" />
											<span>Checked by Google Play Protect</span>
										</p>
									</div>
								)}
								{status === "verifying" && (
									<div
										id="veriying_subtitle"
										className="header-loding"
									>
										<h3 id="titleVerifying">Verifying...</h3>
										<p>
											<img src="/images/other/play.jpeg" />
											<span>Verifying by Google Play Protect...</span>
										</p>
									</div>
								)}
							</div>
						</div>
						<div
							id="statistics_view"
							className="mob-stats"
						>
							<div className="inner">
								<div className="mob-stat">
									<span className="app-comp__mobile-info-rating">
										{data?.langs?.ratingsCount}
										<svg
											enableBackground="new 0 0 24 24"
											version="1.1"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g fill="none">
												<path d="M0,0h24v24H0V0z"></path>
												<path d="M0,0h24v24H0V0z"></path>
											</g>
											<path d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"></path>
										</svg>
									</span>
									<p>
										<span id="headerReviews">
											<span style={{ textTransform: "uppercase" }}>
												{numeral(data?.langs?.reviews).format("0a")}
											</span>{" "}
											{data?.langs?.inscriptions?.reviews}
										</span>
										<img
											src="images/other/info.svg"
											alt="#"
											style={{ width: "12px", marginLeft: "3px" }}
										/>
									</p>
								</div>
								<div className="mob-stat">
									<span className="app-comp__mobile-info-icon">
										<svg
											viewBox="0 0 18 18"
											xmlns="http://www.w3.org/2000/svg"
											width="20"
										>
											<g transform="translate(21.552 22.5) rotate(180)">
												<path
													transform="translate(7.552 7.652)"
													d="M.625,0h8.75A.68.68,0,0,1,10,.723a.68.68,0,0,1-.625.723H.625A.68.68,0,0,1,0,.723.68.68,0,0,1,.625,0Z"
													data-name="Path 288"
												></path>
												<g transform="translate(17.552 20.797) rotate(180)">
													<path
														d="M0,0H9.666V9.666H0Z"
														fill="none"
														data-name="Path 289"
													></path>
													<path
														transform="translate(-4.408 -3.203)"
														d="M8.752,4.642V11.81L5.536,8.678a.677.677,0,0,0-.936,0,.627.627,0,0,0,0,.9l4.343,4.229a.669.669,0,0,0,.929,0l4.343-4.229a.627.627,0,0,0,0-.9.669.669,0,0,0-.929,0L10.07,11.81V4.642a.659.659,0,0,0-1.318,0Z"
														data-name="Path 290"
													></path>
												</g>
												<rect
													transform="translate(4.552 5.5)"
													width="16"
													height="16"
													rx="2"
													fill="none"
													stroke="#000"
													strokeWidth="2"
													data-name="Rectangle 123"
												></rect>
											</g>
										</svg>
									</span>
									<p>{data?.langs?.appSize} MB</p>
								</div>
								<div className="mob-stat">
									<img
										src="images/other/age18.webp"
										alt=""
										style={{ width: "23px", marginBottom: "3px" }}
									/>
									<p>
										<span id="headerRated">{data?.langs?.inscriptions?.ageRating}</span>
										<img
											src="images/other/info.svg"
											alt="#"
											style={{ width: "12px", marginLeft: "3px" }}
										/>
									</p>
								</div>
								<div className="mob-stat">
									<span
										className="app-comp__mobile-info-rating"
										style={{ textTransform: "uppercase" }}
									>
										{numeral(data?.langs?.inscriptions?.countDownloads).format("0a")}+
									</span>
									<p id="headerDownloads">{data?.langs?.inscriptions?.downloads}</p>
								</div>
							</div>
						</div>
						{/* {status === "open" && (
							<div
								className="btns"
								id="installing_btns"
							>
								<button
									style={{
										fontFamily: "Google Sans, Roboto, Arial, sans-serif",
										fontWeight: "500",
									}}
									id="cancel_installing_btn"
									type="button"
									className="btn-secondary cancel"
								>
									{data?.langs?.buttons?.cancel}
								</button>
								<button
									style={{
										fontFamily: "Google Sans, Roboto, Arial, sans-serif",
										fontWeight: "500",
									}}
									type="button"
									id="open_installing_btn"
									className="main-btn disabled"
								>
									{data?.langs?.buttons?.open}
								</button>
							</div>
						)} */}
						{status === "install" && (
							<button
								style={{
									fontFamily: "Google Sans, Roboto, Arial, sans-serif",
									fontWeight: "500",
									backgroundColor: !!data?.langs?.buttonColor ? data?.langs?.buttonColor : "",
								}}
								type="button"
								id="install_button"
								className="main-btn"
								onClick={handleInstall}
							>
								{data?.langs?.buttons?.install}
							</button>
						)}
						{status === "installing" && (
							<button
								style={{
									fontFamily: "Google Sans, Roboto, Arial, sans-serif",
									fontWeight: "500",
									backgroundColor: !!data?.langs?.buttonColor ? data?.langs?.buttonColor : "",
								}}
								type="button"
								id="installing_button"
								className="main-btn"
							>
								{data?.langs?.buttons?.installing}
							</button>
						)}
						{(status === "download" || status === "downloading") && (
							<button
								style={{
									fontFamily: "Google Sans, Roboto, Arial, sans-serif",
									fontWeight: "500",
									backgroundColor: !!data?.langs?.buttonColor ? data?.langs?.buttonColor : "",
								}}
								type="button"
								id="download_button"
								className="main-btn"
								onClick={handleDownload}
							>
								{data?.langs?.buttons?.download}
							</button>
						)}
						{status === "verifying" && (
							<button
								style={{
									fontFamily: "Google Sans, Roboto, Arial, sans-serif",
									fontWeight: "500",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								type="button"
								id="verify_button"
								className="main-btn disabled"
							>
								{data?.langs?.buttons?.verifying}
							</button>
						)}
						{status === "open" && (
							<div
								id="installed_btns"
								className="btns"
							>
								<button
									style={{
										fontFamily: "Google Sans, Roboto, Arial, sans-serif",
										fontWeight: "500",
									}}
									type="button"
									className="btn-secondary"
									id="delete_button"
								>
									{data?.langs?.buttons?.delete}
								</button>
								<button
									style={{
										fontFamily: "Google Sans, Roboto, Arial, sans-serif",
										fontWeight: "500",
										backgroundColor: !!data?.langs?.buttonColor ? data?.langs?.buttonColor : "",
									}}
									id="open_app_button"
									type="button"
									className="main-btn"
									onClick={handleOpen}
								>
									{data?.langs?.buttons?.open}
								</button>
							</div>
						)}
					</div>
					<div className="line-sections"></div>
					<div className="current-platform">
						<div className="current-platform_platform">
							<img
								src="images/other/done.svg"
								alt="#"
							/>
							<span id="btnDevice">{data?.langs?.inscriptions?.phone}</span>
						</div>
					</div>
					<div className="info">
						<div className="Gallery_gallery__W86p0">
							<div
								className="Gallery_galleryContainer__-p-ic"
								id="gallery"
							>
								{!!data?.langs?.slides?.length && (
									<>
										{data?.langs?.slides?.map((item, index) => {
											return (
												<img
													key={index}
													src={`${data?.domain_images}/${data?.id}/images/slides/${data?.langs?.lang}/${item}`}
													className="Gallery_galleryContainer__image__QTM3U image"
													onClick={() => openPopup(index)}
												/>
											);
										})}
									</>
								)}
							</div>
						</div>

						<div className="discrip">
							<div className="discrip-head">
								<h3 id="aboutAppTitle">{data?.langs?.titles?.aboutThisApp}</h3>
								<img
									src="images/other/arrow_forward.svg"
									alt="#"
									style={{ width: "24px", height: "24px" }}
								/>
							</div>
							<div className="text">
								<p
									id="aboutAppText"
									dangerouslySetInnerHTML={{
										__html: data?.langs?.description.replace(/\n/g, "<br>"),
									}}
								></p>
							</div>
						</div>
						<div className="discrip">
							<div className="discrip-head">
								<h3 id="dataSfetyTitle">{data?.langs?.titles?.dataSafety}</h3>
								<img
									src="images/other/arrow_forward.svg"
									alt="#"
									style={{ width: "24px", height: "24px" }}
								/>
							</div>
							<div className="text">
								<p id="dataSfetyText">{data?.langs?.texts?.dataSafety}</p>
							</div>
							<div className="frame">
								<div className="frame-row">
									<img src="images/other/icon-share.png" />
									<div className="frame-text">
										<h4 id="frameTitle1">{data?.langs?.inscriptions?.dataSafety_title_1}</h4>
										<p id="frameText1">
											<span>{data?.langs?.inscriptions?.dataSafety_text_1}</span>
										</p>
									</div>
								</div>
								<div className="frame-row">
									<img src="images/other/icon-cloud.png" />
									<div className="frame-text">
										<h4 id="frameTitle2">{data?.langs?.inscriptions?.dataSafety_title_2}</h4>
										<p id="frameText2">
											<span>{data?.langs?.inscriptions?.dataSafety_text_2}</span>
										</p>
									</div>
								</div>
								<a
									href="#"
									className="btn-link"
									id="seeDetails"
								>
									{data?.langs?.inscriptions?.seeDetails}
								</a>
							</div>
						</div>
						<div className="discrip rating">
							<div className="discrip-head">
								<h3 id="ratingTitle">{data?.langs?.titles?.ratings}</h3>
								<img
									src="images/other/arrow_forward.svg"
									alt="#"
									style={{ width: "24px", height: "24px" }}
								/>
							</div>
							<div className="text">
								<p id="ratingText">{data?.langs?.texts?.ratings}</p>
							</div>
							<div className="inner">
								<div className="left">
									<div className="total-rating">
										{numeral(data?.langs?.ratingsCount).format("0.0")}
									</div>
									<div className="stars">
										{data?.langs?.ratingsCount >= 1 && (
											<img src={"images/other/star-blue-full.png"} />
										)}
										{data?.langs?.ratingsCount >= 2 && (
											<img src={"images/other/star-blue-full.png"} />
										)}
										{data?.langs?.ratingsCount >= 3 && (
											<img src={"images/other/star-blue-full.png"} />
										)}
										{data?.langs?.ratingsCount >= 4 && (
											<img src={"images/other/star-blue-full.png"} />
										)}
										{data?.langs?.ratingsCount >= 4.5 && (
											<img src={"images/other/star-blue-full.png"} />
										)}
									</div>
									<div className="user">{data?.langs?.reviews}</div>
								</div>
								<div className="right">
									<div className="chart">
										<div
											className="chart-bar bar1"
											style={{ "--bar1": `${data?.langs?.ratings?.a1}%` }}
										>
											<p>5</p>
											<span></span>
										</div>
										<div
											className="chart-bar bar2"
											style={{ "--bar2": `${data?.langs?.ratings?.a2}%` }}
										>
											<p>4</p>
											<span></span>
										</div>
										<div
											className="chart-bar bar3"
											style={{ "--bar3": `${data?.langs?.ratings?.a3}%` }}
										>
											<p>3</p>
											<span></span>
										</div>
										<div
											className="chart-bar bar4"
											style={{ "--bar4": `${data?.langs?.ratings?.a4}%` }}
										>
											<p>2</p>
											<span></span>
										</div>
										<div
											className="chart-bar bar5"
											style={{ "--bar5": `${data?.langs?.ratings?.a5}%` }}
										>
											<p>1</p>
											<span></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="comments">
							{data?.langs?.comments?.map((item, index) => {
								return (
									<div
										className="comment"
										key={index}
									>
										<div className="comment-info">
											<div className="top">
												<div className="user-info">
													<div
														className="avatar"
														style={{
															backgroundImage: `url(${data?.domain_images}/${data?.id}/images/users/${data?.langs?.lang}/${item?.avatar})`,
															backgroundRepeat: "no-repeat",
															backgroundPosition: "center",
															backgroundSize: "cover",
														}}
													></div>
													<h4>{item.author}</h4>
													<img
														className="dots"
														src="images/other/dots.png"
													/>
												</div>
												<div className="user-rating">
													<div className="stars">
														{item.rating >= 1 && <img src={"images/other/star-blue-full.png"} />}
														{item.rating >= 2 && <img src={"images/other/star-blue-full.png"} />}
														{item.rating >= 3 && <img src={"images/other/star-blue-full.png"} />}
														{item.rating >= 4 && <img src={"images/other/star-blue-full.png"} />}
														{item.rating === 5 && <img src={"images/other/star-blue-full.png"} />}
													</div>
													<p>{moment(new Date().setHours(0, 0, 0, 0)).format("DD/MM/YYYY")}</p>
												</div>
											</div>
											<div className="comment-text">{item.comment}</div>
											<div className="helpful">
												{item.likes} {data?.langs?.inscriptions?.peopleHelpFull}
											</div>
											<div className="helpful-que-container">
												<span>{data?.langs?.inscriptions?.helpFull}</span>
												<div className="helpful-que-container_buttons">
													<div>{data?.langs?.inscriptions?.yes}</div>
													<div>{data?.langs?.inscriptions?.no}</div>
												</div>
											</div>
											<div className="responce">
												<div className="responce-header">
													<span>{data?.langs?.developer}</span>
													<span>
														{moment(new Date().setHours(0, 0, 0, 0)).format("DD/MM/YYYY")}
													</span>
												</div>
												<div className="responce-text">{item.response}</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<div className="all-reviews">
							<a
								href="#"
								className="btn-link"
								id="allReviews"
							>
								{data?.langs?.inscriptions?.seeAllReviews}
							</a>
						</div>
					</div>
				</div>
			</div>
			{isPopupOpen && (
				<div className="Layout_imagePopup__CCOgX openImage">
					<header className="Layout_imagePopup__header__n7kd7">
						<button onClick={closePopup}>
							<img
								src="images/other/arrow_back.svg"
								alt="Close"
								style={{ width: "24px", height: "24px" }}
							/>
						</button>
					</header>
					<section className="Layout_imagePopup___nwj57">
						<button
							className="Gallery_buttonPrev__GjkHI Layout_buttonPrev__IU3Mn"
							onClick={handlePrevImage}
							style={{ display: currentImage === 0 ? "none" : "flex" }}
						>
							<img
								src="images/other/arrow_left.svg"
								alt="Previous"
								style={{ width: "30px", height: "36px" }}
							/>
						</button>
						<img
							src={`${data?.domain_images}/${data?.id}/images/slides/${data?.langs?.lang}/${data?.langs?.slides[currentImage]}`}
							alt="Popup"
							className="Layout_imagePopup__image"
						/>
						<button
							className="Gallery_buttonNext__+NwwV Layout_buttonNext__p4gU9"
							onClick={handleNextImage}
							style={{
								display: currentImage === data?.langs?.slides?.length - 1 ? "none" : "flex",
							}}
						>
							<img
								src="images/other/arrow_right.svg"
								alt="Next"
								style={{ width: "30px", height: "36px" }}
							/>
						</button>
					</section>
				</div>
			)}
			{isLoader && (
				<div
					className="loaderContainer"
					style={{
						display: "flex",
						width: "100%",
						height: "100vh",
						position: "absolute",
						top: "0px",
						left: "0px",
						backgroundColor: "white",
					}}
				>
					<span className="loader"></span>
				</div>
			)}
		</>
	);
};

export default App;
